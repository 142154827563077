<template>
    <div class="gameDetail">
        <!-- banner -->
        <picture class="banner">
            <source srcset="../../assets/imgs/h5_images/h5_game_detail_lucky5_express.jpg" media="(max-width: 414px)">
            <img src="../../assets/imgs/gameBanner/game_detail_lucky5_express.jpg">
        </picture>
        <!--games -->
        <main>
            <div class="content">
                <div class="left"><img src="../../assets/imgs/gameIcon/lucky_5_express.png"></div>
                <div class="right">
                    <div class="title">
                        <div>Lucky 5</div>
                        <div class="tagWraper">
                            <div class="tag"><img src="../../assets/imgs/tagIcon/express.png"></div>
                            <div class="tag"><img src="../../assets/imgs/tagIcon/75sec.png"></div>
                            <div class="tag"><img src="../../assets/imgs/tagIcon/rtp99.png"></div>
                            <div class="tag"><img src="../../assets/imgs/tagIcon/bmm.png"></div>
                            <div class="logoOld"><img src="../../assets/imgs/maxgate_old_icon/Lucky5_Express.png"></div> 
                        </div>
                    </div>
                    <div class="introduce pc">
                        Lucky 5 games are RNG games that come up with 11 balls numbered 1-11. 5 balls will be drawn. The number cannot be repeated. Players are allowed to select the number on the specific ball. After 75 seconds 5 numbers are randomly generated. If the any of the players’ selected numbers match that of the randomly generated number on the specific ball they win. 
                    </div>
                    <a class="playBtn" href="https://demo.maxgatesoftware.com/#/Lucky5/Express">Play Demo</a>
                </div>
            </div> 
            <div class="introduce mobile">
                Lucky 5 games are RNG games that come up with 11 balls numbered 1-11. 5 balls will be drawn. The number cannot be repeated. Players are allowed to select the number on the specific ball. After 75 seconds 5 numbers are randomly generated. If the any of the players’ selected numbers match that of the randomly generated number on the specific ball they win. 
            </div>
            <div class="picWrapper pc">
                <div class="pic"><img src="../../assets/imgs/gamma3_ex_p1.jpg"></div>
                <div class="pic"><img src="../../assets/imgs/gamma3_ex_p2.jpg"></div> 
                <div class="pic"><img src="../../assets/imgs/gamma3_ex_p3.jpg"></div>  
            </div>
            <div class="picWrapper mobile">
                <div class="pic"><img src="../../assets/imgs/h5_images/h5_screenshot_express.jpg"></div>
            </div>
        </main>
        <!--infor -->
        <section style="background:rgb(246, 246, 247);box-shadow: 2px 2px 3px rgba(161,161,161,0.5) inset">
            <div class="infor">
                <div class="left">
                    <div class="subject">INTRODUCTION</div>
                    <div class="features">
                        <div class="title">How To Play</div>
                        <div class="desc">
                            <p>To play Lucky 5, firstly you might select the 1st ball, the 2nd ball, the 3rd ball, the 4th ball or the 5th ball.</p>
                            <p>You can place your bet on the number of the chosen ball by selecting the corresponding number button.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">Placing Bets</div>
                        <div class="desc">
                            <p>Lucky 5 allows you to place your stake GBP 1, 5, 10, 50 by clicking different chip icon.</p>
                            <p>The amount of your stake will be shown in the field.</p>
                            <p>You might also enter the amount of your bet in the field.</p>
                            <p>Select the “Bet” button to confirm your bet.</p>
                            <p>You will see your transaction record on “Your Recent Betslip” once your bet is confirmed.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">Getting Started</div>
                        <div class="desc">
                            <p>Lucky 5 Express starts every 75 second.</p>
                            <p>The timer shows the remaining time for the next game.</p>
                            <p>The draw result is shown on the tab.</p>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="subject">GAME SPECIFICATIONS</div>
                    <div class="features">
                        <div class="title">Supported Platform</div>
                        <div class="desc">
                            <p>Desktop, Mobile.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">Game Logic</div>
                        <div class="desc">
                            <p>Draw 5 balls between numbered 1-11,  the number cannot be repeated.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">RTP</div>
                        <div class="desc">
                            <p>The return to player percentage (RTP%) for this game is 98.18%.</p>
                        </div>
                    </div>
                    <div class="features">
                        <div class="title">Payouts</div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Bet Type</th>
                                    <th>Selection</th>
                                    <th>Odds</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Correct Number</td>
                                    <td>Number</td>
                                    <td>10.8</td>
                                </tr>
                                <tr>
                                    <td>Draw Sum</td>
                                    <td>Over 30</td>
                                    <td>1.98</td>
                                </tr>
                                <tr>
                                    <td>Draw Sum</td>
                                    <td>Under 30</td>
                                    <td>1.98</td>
                                </tr>
                             
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
        <!--history -->
        <PeriodHistory lotteryCode="Lucky5Ex"/>
        <!--more game -->
        <div class="moreGame">
            <h1>MORE GAMES</h1>
            <div class="gameWrapper">
               
                <a href="/#/games/Penta5_Express">
                    <div class="imgBox"><img src="../../assets/imgs/gameIcon/penta_5_express.png"></div>
                </a>
                <a href="/#/games/Infinity8_Express">
                    <div class="imgBox"><img src="../../assets/imgs/gameIcon/infinity_8_express.png"></div>
                </a>
                <a href="/#/games/Surfing10_Classic">
                    <div class="imgBox"><img src="../../assets/imgs/gameIcon/surfing_10_classic.png"></div>
                </a>
                <a href="/#/games/Cannon20_Express">
                    <div class="imgBox"><img src="../../assets/imgs/gameIcon/cannon_20_express.png"></div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import DatePicker from 'vue2-datepicker';
import PeriodHistory from '@/components/PeriodHistory';
import 'vue2-datepicker/index.css';
import lib from '@/lib';
export default {
    components: { DatePicker,PeriodHistory },
}
</script>
